<template>
  <div class="tml-product-stock">
    <font-awesome-icon v-if="stock > 20" class="mr-2" :icon="faCheckCircle" />
    <font-awesome-icon
      v-else-if="stock > 0"
      class="mr-2"
      :icon="faInfoCircle"
    />
    <font-awesome-icon v-else class="mr-2" :icon="faTimesCircle" />
    <transition name="appear-left" mode="out-in">
      <span :key="stockText">{{ stockText }}</span>
    </transition>
  </div>
</template>

<script>
import {faCheckCircle} from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import {faTimesCircle} from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import {faInfoCircle} from '@fortawesome/pro-light-svg-icons/faInfoCircle';

export default {
  name: 'ProductStock',
  props: {
    stock: {
      type: [String, Number],
      required: true,
      default: 0,
    },
    mentionColour: {
      type: Boolean,
      default: true,
    },
    mentionSize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      faCheckCircle,
      faTimesCircle,
      faInfoCircle,
    };
  },
  computed: {
    stockText() {
      if (this.stock > 20) {
        return 'In stock now.';
      } else if (this.stock > 0) {
        return 'Low stock.';
      }

      let subject = '';

      if (this.mentionColour && this.mentionSize) {
        subject = 'colour and size ';
      } else if (this.mentionColour) {
        subject = 'colour ';
      } else if (this.mentionSize) {
        subject = 'size ';
      }

      return `Sorry! This ${subject}is currently out of stock.`;
    },
  },
};
</script>

<style lang="scss" scoped>
.appear-left-enter-active {
  display: inline-block;
  transition: transform 0.3s, opacity 0.3s;

  &.appear-left-enter-from {
    transform: translateX(-0.5em);
    opacity: 0;
  }
  &.appear-left-enter-to {
    transform: translateX(0);
    opacity: 1;
  }
}
.appear-left-leave-active {
  display: inline-block;
  transition: transform 0.3s, opacity 0.3s;

  &.appear-left-leave-from {
    transform: translateX(0);
    opacity: 1;
  }
  &.appear-left-leave-to {
    transform: translateX(-0.5em);
    opacity: 0;
  }
}
</style>
